import * as EmojiMart from 'emoji-mart';
import EmojiMartData from '@emoji-mart/data';
import { ChannelFilters, ChannelSort, User, UserSort } from 'stream-chat';
import { Chat, Channel, ChannelHeader, ChannelList, MessageInput, MessageList, Thread, Window, useCreateChatClient, ChannelSearchParams } from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import { useMemo } from 'react';

import './css/index.css';
import TokenProviderFactory from './lib/token_provider_factory';
import NotificationContainer from './components/NotificationContainer';
import SystemNotification from './lib/system_notification';

EmojiMart.init({EmojiMartData});

export interface AppProps {
  accessToken?: string;
  apiKey: string;
  appId: string;
  refreshTokenUrl?: string;
  userId: string;
  userName: string;
}

const App = ({accessToken, apiKey, refreshTokenUrl, userId, userName}: AppProps): JSX.Element => {
  const tokenProvider = useMemo(
    () => new TokenProviderFactory(refreshTokenUrl, accessToken).build(),
    [refreshTokenUrl, accessToken]
  );

  const user: User = {
    id: userId,
    name: userName,
    image: `https://getstream.io/random_png/?name=${userName}`,
  };

  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: tokenProvider,
    userData: user,
  });

  if (!client) {
    return <div className="loading-indicator">
      <span className="text-center">
        Connecting...
        <i className="fa fa-solid fa-fw fa-spin fa-spinner ml-2"></i>
      </span>
    </div>;
  }

  const channelListFilters: ChannelFilters = { members: { $in: [userId] } };
  const channelListSorting: ChannelSort = [{ last_message_at: -1 }, { unread_count: 1 }, { created_at: -1 }];
  // We would also have liked to sort by the user's online status, but there's a
  // bug in the Stream React library that prevents us from using the array
  // syntax for sorting by multiple fields. They've suppressed the bug by using
  // a ts-error-expect, but ultimately they are trying to splat an array into an
  // object rather than combine arrays.
  // https://github.com/GetStream/stream-chat-react/blob/efa073638f0e6cc0198b12aaedc3225199b16dee/src/components/ChannelSearch/hooks/useChannelSearch.ts#L221
  const userSearchSorting: UserSort = { name: 1 };  // [{ online: -1 }, { name: 1 }];
  const userSearchProps: ChannelSearchParams = {
    searchForChannels: false,
    channelType: "messaging",
    searchQueryParams: { userFilters: { sort: userSearchSorting } }
  };

  const notificationBanner = SystemNotification.supported() ? <NotificationContainer client={client} userId={userId} /> : null;

  return (
    <Chat client={client}>
      {notificationBanner}
      <ChannelList filters={channelListFilters} sort={channelListSorting} showChannelSearch additionalChannelSearchProps={userSearchProps} />
      <Channel EmojiPicker={EmojiPicker} emojiSearchIndex={EmojiMart.SearchIndex}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default App;
