import { PERMISSION_DENIED } from "../lib/notification_setting";

interface Props {
  setPermission: (permission: NotificationPermission) => void;
}

export const NotificationPermissionBanner = ({setPermission}: Props) => {
  const hideBanner = () => setPermission(PERMISSION_DENIED);
  const requestPermission = () => Notification.requestPermission().then(setPermission);

  return (
    <div className="notification-permission-banner">
      <span className="notification-permission-banner__icon fa-stack fa-2x">
        <i className="fa-solid fa-square fa-stack-2x"></i>
        <i className="fa-solid fa-info-circle fa-stack-1x fa-inverse"></i>
      </span>
      <div className="notification-permission-banner__content">
        <p className="notification-permission-banner__text">
          We need your permission to enable notifications.
        </p>
        <div className="notification-permission-banner__actions">
          <button className="notification-permission-banner__button c-btn c-btn--cancel c-btn--xs" onClick={hideBanner}>Hide</button>
          <button className="notification-permission-banner__button c-btn c-btn--primary c-btn--xs" onClick={requestPermission}>Enable</button>
        </div>
      </div>
    </div>
  );
};

export default NotificationPermissionBanner;
