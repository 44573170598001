import { Event, MessageResponse } from 'stream-chat';

const NOTIFICATION_ICON = '/logo-square.png';

export default class SystemNotification {
  static supported() {
    return "Notification" in window;
  }

  userId: string;
  currentChannelId: string | undefined;

  constructor(userId: string, currentChannelId: string | undefined) {
    this.userId = userId;
    this.currentChannelId = currentChannelId;
  }

  postSystemNotification(event: Event) {
    if (this.shouldNotify(event)) {
      const message = event.message!;
      const user = message.user?.name || "Someone";
      const text = `${user}: ${message.text}`;

      new Notification("New Kode Chat Message", { body: text, badge: NOTIFICATION_ICON, icon: NOTIFICATION_ICON });
    }
  }

  shouldNotify(event: Event): boolean {
    if (!event.message) return false;

    if (!this.isNewMessage(event)) return false;
    if (this.isFromFocusedChannel(event)) return false;
    if (this.isFromCurrentUser(event.message)) return false;

    if (this.wasMentioned(event.message)) return true;
    if (this.isDirectMessage(event)) return true;

    return false;
  }

  isDirectMessage(event: Event) {
    return event.channel_type === 'messaging';
  }

  isFromCurrentUser(message: MessageResponse) {
    return message.user?.id === this.userId;
  }

  isFromFocusedChannel(event: Event) {
    return document.hasFocus() && event.channel_id === this.currentChannelId;
  }

  isNewMessage(event: Event) {
    return event.type === 'message.new';
  }

  wasMentioned(message: MessageResponse) {
    return message.mentioned_users?.find((user) => user.id === this.userId);
  }
}
