import { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";

import NotificationPermissionBanner from "./NotificationPermissionBanner";
import NotificationSetting from "../lib/notification_setting";
import SystemNotification from "../lib/system_notification";
import { useChatContext } from "stream-chat-react";

interface Props {
  client: StreamChat;
  userId: string;
}

export const NotificationContainer = ({client, userId}: Props): JSX.Element => {
  const [permission, setPermission] = useState(Notification.permission);
  const { channel } = useChatContext();
  const currentChannelId = channel?.id;

  useEffect(() => {
    if (NotificationSetting.isGranted(permission)) {
      const systemNotification = new SystemNotification(userId, currentChannelId);
      const listener = client.on((event) => { 
        systemNotification.postSystemNotification(event);
      });
      return listener.unsubscribe;
    }
  }, [client, permission, userId, currentChannelId]);

  if (NotificationSetting.isDefault(permission)) {
    return <NotificationPermissionBanner setPermission={setPermission} />;
  } else {
    return <></>;
  }
}

export default NotificationContainer;
