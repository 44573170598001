import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client';
import App from './App.tsx'
import {AppProps} from './App.tsx'

const isBlank = (value: string | undefined): boolean => !value || value.length === 0;

const validateApplicationConfig = (props: Partial<AppProps>): AppProps => {
  // Only one of accessToken / refreshTokenUrl is required.
  const {accessToken, refreshTokenUrl, ...required} = props;

  const missingProps = Object.entries(required)
    .filter(([_, value]) => isBlank(value))
    .map(([key, _value]) => [key, "is required"]);

  if (isBlank(accessToken) && isBlank(refreshTokenUrl)) {
    missingProps.push(["accessToken | refreshTokenUrl", "at least one is required"]);
  }

  if (missingProps.length > 0) {
    const errors = missingProps.map(([key, msg]) => `${key} ${msg}`).join(", ");
    throw Error(`Missing application configuration properties: ${errors}`);
  } else {
    return props as AppProps;
  }
}

const rootEl = document.querySelector(".kode-chat-root") || document.getElementById('root') as HTMLElement;
const rootData = (key: string): string | undefined => rootEl.getAttribute(`data-${key}`) || undefined;

const {accessToken, apiKey, appId, refreshTokenUrl, userId, userName}: AppProps = validateApplicationConfig({
  accessToken: rootData("access-token"),
  apiKey: rootData("api-key"),
  appId: rootData("app-id"),
  refreshTokenUrl: rootData("refresh-token-url"),
  userId: rootData("user-id"),
  userName: rootData("user-name"),
});

ReactDOM.createRoot(rootEl).render(
  <StrictMode>
    <App
      accessToken={accessToken}
      apiKey={apiKey}
      appId={appId}
      refreshTokenUrl={refreshTokenUrl}
      userId={userId}
      userName={userName}
    />
  </StrictMode>,
)
