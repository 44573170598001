import * as EmojiMart from 'emoji-mart';
import EmojiMartData from '@emoji-mart/data';
import { ChannelFilters, User } from 'stream-chat';
import { Chat, Channel, ChannelHeader, ChannelList, MessageInput, MessageList, Thread, Window, useCreateChatClient } from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import { useMemo } from 'react';

import './css/index.css';
import TokenProviderFactory from './lib/token_provider_factory';
import NotificationContainer from './components/NotificationContainer';

EmojiMart.init({EmojiMartData});

export interface AppProps {
  accessToken?: string;
  apiKey: string;
  appId: string;
  refreshTokenUrl?: string;
  userId: string;
  userName: string;
}

const App = ({accessToken, apiKey, refreshTokenUrl, userId, userName}: AppProps): JSX.Element => {
  const tokenProvider = useMemo(
    () => new TokenProviderFactory(refreshTokenUrl, accessToken).build(),
    [refreshTokenUrl, accessToken]
  );

  const user: User = {
    id: userId,
    name: userName,
    image: `https://getstream.io/random_png/?name=${userName}`,
  };

  const filters: ChannelFilters = {
    members: { $in: [userId] },
  };

  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: tokenProvider,
    userData: user,
  });

  if (!client) {
    return <div className="loading-indicator">
      <span className="text-center">
        Connecting...
        <i className="fa fa-solid fa-fw fa-spin fa-spinner ml-2"></i>
      </span>
    </div>;
  }

  return (
    <Chat client={client}>
      <NotificationContainer client={client} userId={userId} />
      <ChannelList filters={filters} sort={{ last_message_at: -1 }} />
      <Channel EmojiPicker={EmojiPicker} emojiSearchIndex={EmojiMart.SearchIndex}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default App;
