// These the possible values are from the Notification API.
// See https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API#checking_current_permission_status
//
// The `NotificationPermission` type is pre-defined by the standard Web API
// typedefs, but there are not accompanying constants defined that I can find.
export const PERMISSION_DEFAULT = 'default';
export const PERMISSION_DENIED = 'denied';
export const PERMISSION_GRANTED = 'granted';

export const NotificationSetting = {
  isGranted: (permission: NotificationPermission) => permission === PERMISSION_GRANTED,
  isDefault: (permission: NotificationPermission) => permission === PERMISSION_DEFAULT,
  isDenied: (permission: NotificationPermission) => permission === PERMISSION_DENIED,
};

export default NotificationSetting;
