import { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";

import NotificationPermissionBanner from "./NotificationPermissionBanner";
import NotificationSetting from "../lib/notification_setting";
import SystemNotification from "../lib/system_notification";

interface Props {
  client: StreamChat;
  userId: string;
}

export const NotificationContainer = ({client, userId}: Props): JSX.Element => {
  const [permission, setPermission] = useState(Notification.permission);

  useEffect(() => {
    if (NotificationSetting.isGranted(permission)) {
      const systemNotification = new SystemNotification(userId);
      const listener = client.on(systemNotification.postSystemNotification.bind(systemNotification));
      return listener.unsubscribe;
    }
  }, [client, permission, userId]);

  if (NotificationSetting.isDefault(permission)) {
    return <NotificationPermissionBanner setPermission={setPermission} />;
  } else {
    return <></>;
  }
}

export default NotificationContainer;
