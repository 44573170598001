import { Event, MessageResponse } from 'stream-chat';

const NOTIFICATION_ICON = '/logo-square.png';

export default class SystemNotification {
  userId: string;

  constructor(userId: string) {
    this.userId = userId;
  }

  postSystemNotification(event: Event) {
    if (!event.message) return;
    if (!this.isNewMessage(event)) return;

    const message = event.message;

    if (this.wasMentioned(message)) {
      const user = message.user?.name || "Someone";
      const text = `${user}: ${message.text}`;

      new Notification("New Kode Chat Message", { body: text, badge: NOTIFICATION_ICON, icon: NOTIFICATION_ICON });
    }
  }

  isNewMessage(event: Event) {
    return event.type === 'message.new';
  }

  wasMentioned(message: MessageResponse) {
    return message.mentioned_users?.find((user) => user.id === this.userId);
  }
}
